<template>
  <div>

      <!----- 搜索框 start------->
      <el-form :inline="true" class="search">
          <el-form-item label="项目">
              <el-select v-model="searchData.project_id" @change="getGroups" placeholder="筛选项目" filterable>
                  <el-option
                          v-for="(item, index) in formData.relate.project"
                          :key="index"
                          :label="item.name + '[' + item.id + ']'"
                          :value="item.id">
                  </el-option>
              </el-select>
          </el-form-item>
          <el-form-item label="分组">
              <el-select v-model="searchData.group_id" placeholder="筛选分组" filterable>
                  <el-option
                      v-for="(item, index) in formData.relate.groups"
                      :key="index"
                      :label="item.name + '[' + item.id + ']'"
                      :value="item.id">
                  </el-option>
              </el-select>
          </el-form-item>
          <el-form-item label="状态">
              <el-select v-model="searchData.status" placeholder="状态筛选" filterable>
                 <el-option label="待审核" value="1"></el-option>
                  <el-option label="已审核" value="2"></el-option>
                  <el-option label="已发布" value="3"></el-option>
              </el-select>
          </el-form-item>
          <el-form-item label="标题">
              <el-input v-model="searchData.title" placeholder="文章标题搜索" clearable />
          </el-form-item>
          <el-form-item>
              <el-button @click="getNotes()">查询</el-button>
          </el-form-item>

          <el-form-item style="float: right">
              <el-button type="warning" @click.stop="clickExport()">导出</el-button>
              <el-button type="primary" @click.stop="clickCreate()">写笔记</el-button>
          </el-form-item>
      </el-form>
      <!----- 搜索框 end------->

      <!--------  瀑布流 start ----------->

      <Waterfall :list="tableData" :gutter="5" :breakpoints="breakpoints">
          <template #item="{ item }" :rowKey="item.id">
              <el-card>
                  <div style="font-size:12px;white-space: pre-wrap;">
                      <div>
                          <b>
                              <span v-text="item.id + '. '"></span>
                              <span v-text="item.title"></span>
                          </b>
                      </div>
                      <div><span v-text="item.content" /></div>
                      <div>
                          <el-button
                              v-show="item.status === 1"
                              size="small"
                              type="warning">
                              审核
                          </el-button>
                          <el-button
                              @click="clickEdit(item)"
                              size="small"
                              type="primary">
                              编辑
                          </el-button>
                      </div>
                  </div>
              </el-card>
          </template>
      </Waterfall>

      <!--------  瀑布流 end ----------->

      <!----- 分页 start------->
      <div class="cherry-page" v-show="pageData.total">
          <el-pagination
                  small
                  background
                  layout="prev, pager, next, total"
                  :total="pageData.total"
                  v-model:current-page="searchData.page"
                  @update:current-page="getNotes()"
                  class="mt-4"
          />
      </div>
      <!----- 分页 end------->

      <!-------- 弹出框表单 start ---------->
      <el-dialog v-model="formData.show" title="写笔记" fullscreen append-to-body>
          <el-form
              ref="note"
              :rules="formData.rules"
              :model="formData.data"
          >
              <el-form-item label="项目" prop="project_id" v-show="!formData.data.id">
                  <el-select
                      v-model="formData.data.project_id"
                      placeholder="选择项目"
                      @change="getFormGroups"
                      filterable>
                      <el-option
                          v-for="(item, index) in formData.relate.project"
                          :key="index"
                          :label="item.name + '[' + item.id + ']'"
                          :value="item.id">
                      </el-option>
                  </el-select>
              </el-form-item>
              <el-form-item label="分组">
                  <el-select v-model="formData.data.group_id" placeholder="选择分组/批次" filterable>
                      <el-option
                          v-for="(item, index) in formData.relate.form_groups"
                          :key="index"
                          :label="item.name + '[' + item.id + ']'"
                          :value="item.id">
                      </el-option>
                  </el-select>
              </el-form-item>
              <el-form-item label="标题" prop="title">
                  <el-input
                      v-model="formData.data.title"
                      show-word-limit
                      maxlength="20"
                      placeholder="笔记标题"/>
              </el-form-item>
              <el-form-item label="内容" prop="content">
                  <el-input
                      type="textarea"
                      rows="10"
                      show-word-limit
                      minlength="5"
                      maxlength="1500"
                      v-model="formData.data.content"
                      placeholder="笔记内容" />
              </el-form-item>
          </el-form>

          <template #footer>
              <span class="dialog-footer">
                <el-button @click="closeDialog">取消</el-button>
                <el-button type="primary" @click="onSubmit">提交</el-button>
              </span>
          </template>
      </el-dialog>
      <!-------- 弹出框表单 end ---------->
  </div>
</template>

<script>
  import { Waterfall } from 'vue-waterfall-plugin-next'
  import 'vue-waterfall-plugin-next/dist/style.css'
  import {apiGetNotes, apiCreateNote, apiUpdateNote, apiExportNote, apiGetNoteGroups} from '@/api/noteApi'
  import {apiGetProjects} from '@/api/projectApi'

  const form = {
      id:0,
      project_id: '',
      group_id: '',
      title: '',
      content: '',
  }

  export default {
      name: 'note.square',
      created() {
          this.getNotes()
          this.getProject()
      },
      components: {
          Waterfall
      },
      data () {
        return {
            breakpoints: {
                //当屏幕宽度小于等于1200
                1200: {rowPerView: 4},
                800: {rowPerView: 3},
                500: {rowPerView: 2}
            },
            formData: {
                show: false,
                lock: false,
                data: form,
                relate: {
                    project: [],
                    groups: []
                },
                rules: {
                    project_id: [{required: true, message: '请选择项目', trigger: 'blur'}],
                    title: [
                        {required: true, message: '请输入项目标题', trigger: 'blur'},
                        {type: 'string', max: 20, message: '标题不能大于20字', trigger: 'blur'}
                    ],
                    content: [
                        {required: true, message: '请输入笔记内容', trigger: 'blur'},
                        {type: 'string', min: 5, message: '内容请大于5字', trigger: 'blur'}
                    ],
                }
            },
            searchData: {
                group_id: '',
                project_id: '',
                title: '',
                page: 1,
                status: '',
            },
            pageData: {
                total: 0,
            },
            tableData: [],
        }
      },
      methods: {
          getNotes() {
              apiGetNotes(this.searchData).then(data => {
                  this.pageData = data.meta
                  this.tableData = data.data
              })
          },
          // 获取项目
          getProject(force = false) {
              if (this.formData.relate.project.length && !force) {
                  return
              }

              apiGetProjects({per_page: 200}).then(res => {
                  this.formData.relate.project = res.data
              })
          },
          getGroups(value) {
              apiGetNoteGroups({project_id: value}).then(res => {
                  this.formData.relate.groups = res.data
              })
          },
          getFormGroups(value) {
              apiGetNoteGroups({project_id: value}).then(res => {
                  this.formData.relate.form_groups = res.data
              })
          },
          onSubmit () {
              if (this.formData.lock) {
                  return;
              }

              this.$refs['note'].validate((valid) => {
                  this.formLock()
                  if (valid) {
                      if (this.formData.data.id) {
                          apiUpdateNote(this.formData.data.id, this.formData.data).then(() => {
                              this.$message.success('修改成功')
                              this.getNotes()
                              this.closeDialog()
                              this.formUnlock()
                          }).catch(() => {
                              this.formUnlock()
                          })
                      } else {
                          apiCreateNote(this.formData.data).then(() => {
                              this.$message.success('创建成功')
                              this.getNotes()
                              this.closeDialog()
                              this.formUnlock()
                          }).catch(() => {
                              this.formUnlock()
                          })
                      }
                  }

              })
          },
          // 点击导出
          clickExport () {
              if (!this.searchData.project_id) {
                  this.$message.warning('请选择项目')
                  return
              }

              if (!this.searchData.group_id) {
                  this.$message.warning('请选择分组/批次')
                  return
              }

              window.open(apiExportNote(this.searchData))

              this.$message.success('导出成功')
          },
          // 创建项目
          clickCreate () {
              this.formData.data = form
              this.showDialog()
          },
          clickEdit (item) {
              if (item.project_id) {
                  this.getFormGroups(item.project_id)
              }
              this.formData.data = item
              this.showDialog()
          },
          closeDialog () {
              this.formData.show = false
          },
          showDialog () {
              this.formData.show = true
          },
          formLock() {
              this.formData.lock = true
          },
          formUnlock() {
              this.formData.lock = false
          }
      }
  }
</script>

